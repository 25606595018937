import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import {
  DatasetVendorName,
  DatasetServiceCategory,
  DatasetServiceSubcategory,
  DatasetApplicableGeography,
  DatasetApplicableSector,
  IFilters,
  DatasetStatus,
} from "../../types/datasets";

interface IFilterCategory {
  category: Set<
    | DatasetVendorName
    | DatasetServiceCategory
    | DatasetServiceSubcategory
    | DatasetApplicableGeography
    | DatasetApplicableSector
    | DatasetStatus
  >;
  name: string;
  datasetFieldName: string;
  setFilters: (filters: IFilters) => void;
  filters: IFilters;
  updateFilterQueryParam: (queryString: string) => void;
}

const MAX_HEIGHT_PIXELS = 384;

const FilterCategory: React.FC<IFilterCategory> = ({
  category,
  name,
  datasetFieldName,
  setFilters,
  filters,
  updateFilterQueryParam,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const transitionElement = useRef<HTMLUListElement>(null);
  const [currentHeight, setCurrentHeight] = useState<number>(0);

  useEffect(() => {
    // Hook to set current height of category after it transitions to open and closed states.
    const element = transitionElement.current;
    if (element) {
      const handleTransitionEnd = () => {
        setCurrentHeight(element.clientHeight);
      };

      // Add event listener for the transitionend event
      element.addEventListener("transitionend", handleTransitionEnd);

      // Clean up event listener
      return () =>
        element.removeEventListener("transitionend", handleTransitionEnd);
    }
  }, []);

  const handleFilterSelection = (selection: string, isSelected: boolean) => {
    const determineNewFilterQueryParams = (newFilters: IFilters) => {
      const filters = [];
      for (const category in newFilters) {
        if (Object.values(newFilters[category]).length > 0)
          filters.push({
            name: category,
            values: Object.keys(newFilters[category]).map((option) => option),
          });
      }
      updateFilterQueryParam(
        filters.length ? encodeURIComponent(JSON.stringify(filters)) : "",
      );
    };

    const category = selection.split("<>")[0];
    const item = selection.split("<>")[1];

    const newFilters = { ...filters };
    if (isSelected) newFilters[category][item] = true;
    else delete newFilters[category][item];

    determineNewFilterQueryParams(newFilters);
    setFilters(newFilters);
  };

  return (
    <div className="relative mb-6" key={name}>
      <div className="w-full">
        <div className="pb-3 border-b border-gray-light border-opacity-40">
          <div
            className="w-full text-left flex justify-between cursor-pointer mb-4 pr-4 font-semibold items-center"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div>{name}</div>
            <ChevronDownIcon
              className={`h-4 transform transition-transform duration-700 ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          </div>
          {/* Only show scrollbar if contents of this filter section expand beyond its max height */}
          <ul
            className={`transition-[max-height] duration-700 pr-2
                                ${isOpen ? "max-h-96" : "max-h-0"}
                                ${
                                  currentHeight >= MAX_HEIGHT_PIXELS
                                    ? "scrollbar-thin scrollbar-thumb-gray-light scrollbar-track-white scrollbar-thumb-rounded pr-2 overflow-y-scroll overflow-x-hidden"
                                    : "overflow-hidden"
                                }
                            `}
            ref={transitionElement}
          >
            {Array.from(category)
              .sort()
              .map((filterOption, i) => {
                return (
                  <li
                    key={filterOption + i}
                    className="flex items-center gap-2 mb-3"
                  >
                    <input
                      type="checkbox"
                      className="h-5 cursor-pointer"
                      id={`${datasetFieldName}<>${filterOption}`}
                      onChange={(e) => {
                        handleFilterSelection(e.target.id, e.target.checked);
                      }}
                      checked={!!filters[datasetFieldName][filterOption]}
                    />
                    <label
                      htmlFor={`${datasetFieldName}<>${filterOption}`}
                      className="cursor-pointer w-full"
                    >
                      {filterOption}
                    </label>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FilterCategory;
