import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Dispatch, Fragment, SetStateAction } from "react";
import {
  DatasetStatus,
  DatasetVendor,
  IDataset,
  IFilters,
} from "../../types/datasets";
import FilterCategory from "./FilterCategory";
import { parseDatasetTag } from "../../utils";
import { IN_HOUSE_DATASETS_STATUS_VALUE } from "../../constants";
import { Dialog, Transition } from "@headlessui/react";

interface IDatasetFilter {
  isFilterOpen: boolean;
  setIsFilterOpen: (bool: boolean) => void;
  datasets: IDataset[];
  setFilters: Dispatch<SetStateAction<IFilters>>;
  filters: IFilters;
  translations: { [key: string]: string };
  updateFilterQueryParam: (queryString: string) => void;
}

const DatasetFilter: React.FC<IDatasetFilter> = ({
  isFilterOpen,
  setIsFilterOpen,
  datasets,
  setFilters,
  filters,
  translations,
  updateFilterQueryParam,
}) => {
  const renderFilterPanel = () => {
    const generateFilterOptions = (
      datasetField: keyof IDataset,
    ): Set<string> => {
      const options = new Set<string>();

      for (const dataset of datasets) {
        const datasetFieldValue:
          | DatasetVendor
          | string[]
          | string
          | null
          | boolean = dataset[datasetField];
        if (datasetFieldValue == null) continue;

        if (datasetField === "status") {
          if (
            IN_HOUSE_DATASETS_STATUS_VALUE.has(
              datasetFieldValue as DatasetStatus,
            )
          )
            options.add("Yes");
          else options.add("No");
        } else if (datasetField === "vendor") {
          options.add((datasetFieldValue as DatasetVendor)?.name);
        } else if (typeof datasetFieldValue === "object") {
          for (const value of datasetFieldValue as string[]) {
            options.add(parseDatasetTag(value, translations));
          }
        } else {
          options.add(parseDatasetTag(datasetFieldValue as any, translations)); // TODO: check type
        }
      }
      return options;
    };

    const filterCategories = [
      {
        category: generateFilterOptions("vendor"),
        name: "Vendor",
        datasetFieldName: "vendor",
      },
      {
        category: generateFilterOptions("serviceCategory"),
        name: "Category",
        datasetFieldName: "serviceCategory",
      },
      {
        category: generateFilterOptions("serviceSubcategory"),
        name: "Subcategory",
        datasetFieldName: "serviceSubcategory",
      },
      {
        category: generateFilterOptions("applicableSector"),
        name: "Sectors",
        datasetFieldName: "applicableSector",
      },
      {
        category: generateFilterOptions("applicableGeography"),
        name: "Geography",
        datasetFieldName: "applicableGeography",
      },
      {
        category: generateFilterOptions("applicableAssetClass"),
        name: "Asset",
        datasetFieldName: "applicableAssetClass",
      },
      {
        category: generateFilterOptions("status"),
        name: "In-House",
        datasetFieldName: "status",
      },
    ];

    return filterCategories.map((filterCategory, index) => {
      return (
        <FilterCategory
          key={`${index}-${filterCategory.name}`}
          category={filterCategory.category}
          name={filterCategory.name}
          datasetFieldName={filterCategory.datasetFieldName}
          setFilters={setFilters}
          filters={filters}
          updateFilterQueryParam={updateFilterQueryParam}
        />
      );
    });
  };

  return (
    <Transition.Root show={isFilterOpen} as={Fragment}>
      <Dialog as="div" open={isFilterOpen} onClose={setIsFilterOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden z-10">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md mt-18">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-600">
                          Filters
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            onClick={() => setIsFilterOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-8 flex-1 px-4 sm:px-6">
                      {renderFilterPanel()}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DatasetFilter;
