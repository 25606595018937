import React from "react";
import { IDataset } from "../../types/datasets";
import { parseDatasetTag } from "../../utils";
import DatasetTile from "../DatasetTile";

interface DatasetListProps {
  datasets: IDataset[];
  translations: { [key: string]: string };
}

const DatasetList: React.FC<DatasetListProps> = ({
  datasets,
  translations,
}) => {
  return (
    <div className="mt-4 mb-6">
      <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-5 xl:gap-4 2xl:gap-5 3xl:gap-6">
        {datasets.map((dataset, index) => (
          <li key={`${index}-${dataset.id}`}>
            <DatasetTile
              id={dataset.id}
              title={dataset.name}
              description={dataset.shortServiceDescription || ""}
              tag={parseDatasetTag(
                dataset.serviceSubcategory || "",
                translations,
              )}
              vendorName={dataset.vendor.name}
              status={dataset.status}
              isFeatured={dataset.isFeatured}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DatasetList;
